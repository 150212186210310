import axios from 'axios';
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useAuth } from '../_helper/Auth/AuthProvider';
import { Btn } from "../AbstractElements";
import { ForgotPassword, Password } from "../Constant";

import { useNavigate } from "react-router-dom";
import man from "../assets/images/dashboard/profile.png";

import { ToastContainer, toast } from "react-toastify";
import CustomizerContext from "../_helper/Customizer";

import { Link } from 'react-router-dom';
import { Image } from '../AbstractElements';
import LogoInForm from '../assets/images/logo/inpro-logo.png';

const Signin = ({ selected }) => {
  const [togglePassword, setTogglePassword] = useState(false);
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);

  const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
  const [name, setName] = useState(localStorage.getItem("Name"));

  const { t } = useTranslation();
  const { getUserInfo, login } = useAuth();
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [showPassword, setShowPassword] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  useEffect(() => {
    localStorage.setItem("profileURL", man);
    localStorage.setItem("Name", "Emay Walter");
  }, [value, name]);

  const loginAuth = async (e) => {
    e.preventDefault();
    setIsLoggingIn(true);

    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const backendPort = process.env.REACT_APP_BACKEND_PORT;

    let apiUrl = `${backendUrl}`;

    if (backendPort) {
      apiUrl += `:${backendPort}`;
    }

    try {
      const response = await axios.post(apiUrl += '/api/login', {
        email,
        password,
      });

      const token = response.data.token;
      localStorage.setItem('login', JSON.stringify(true));
      login(token);
      history(`${process.env.PUBLIC_URL}/pages/home`);
      toast.success('Successfully logged in!..', { autoClose: 1000 });
      setIsLoggingIn(false);
      getUserInfo(token);
      // Reload the page
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      console.log('ERR', error);
      setIsError(true);
      setIsLoggingIn(false);
      if (error.response && error.response.status === 401) {
        toast.error(error.response.data.error, { autoClose: 1000 });
      }
    }
  };

  return (
    <Fragment>
      <Container fluid={true} className="p-0 login-page">
        <Row>
          <Col xs="12">
            <div className="login-card">
              <div className="login-main login-tab">
                <Form className="theme-form">
                  <div style={{ background: '#002878', height: 100, width: '100%', borderRadius: 5, marginBottom: 20, display: 'flex', alignItems: 'center' }}>
                    {/* Left-aligned logo */}
                    <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 20 }}>
                      <Image attrImage={{ className: 'img-fluid d-inline', src: `${LogoInForm}`, alt: '', style: { width: '60px', height: '60px', marginRight: 15, filter: 'brightness(0) invert(100%)' } }} />
                    </div>

                    {/* Spacer to push "InForm" to center */}
                    <div style={{ paddingLeft: "10px", justifyContent: 'center' }}>
                      <Link to={`${process.env.PUBLIC_URL}/pages/home`} style={{ fontSize: 32, fontWeight: 450, color: '#ffffff', textAlign: 'center' }}>
                        <span style={{ fontFamily: 'Space Grotesk, sans-serif', fontWeight: 'bold' }}>InForm</span>
                      </Link>
                    </div>
                  </div>


                  <FormGroup>
                    <Label className="col-form-label">User</Label>
                    <Input className="form-control" type="email" onChange={(e) => setEmail(e.target.value)} value={email} placeholder='LDAP-Username' />
                  </FormGroup>
                  <FormGroup className="position-relative">
                    <Label className="col-form-label">{Password}</Label>
                    <div className="position-relative">
                      <Input className="form-control" type={togglePassword ? "text" : "password"} value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Enter your password' />
                      <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                        <span className={togglePassword ? "" : "show"}></span>
                      </div>
                    </div>
                  </FormGroup>
                  <div className="position-relative form-group mb-0">
                    <div className="checkbox" >
                      <Input id="checkbox1" type="checkbox" />
                      {/* <Label className="text-muted" for="checkbox1">
                        {RememberPassword}
                      </Label> */}
                    </div>
                    <a className="link" href="#javascript">
                      {ForgotPassword}
                    </a>
                    <div className="position-relative form-group mb-0">
                      {/* <Btn
                      style={{background: 'green'}}
                        attrBtn={{
                          //color: 'primary',
                          className: 'd-block w-100 mt-4',
                          onClick: (e) => loginAuth(e),
                        }}
                      >
                        {isLoggingIn ? 'LOGGING IN...' : 'LOG IN'}
                      </Btn> */}
                      <button className='d-block w-100 mt-4' style={{ background: '#002878', border: 'none', borderRadius: 5, height: 48, color: '#ffffff' }} onClick={(e) => loginAuth(e)}>
                        {isLoggingIn ? 'LOGGING IN...' : 'LOG IN'}
                      </button>
                    </div>
                  </div>

                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default Signin;