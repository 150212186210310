import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Card, CardBody, CardHeader, Table } from 'reactstrap'
import { H3, H4, H6, P } from '../../AbstractElements'
import { useProject } from '../../_helper/Projects/ProjectProvider'

const DashboardCard = ({category}) => {
  const { t } = useTranslation()
  const { searchText, groupedAssets } = useProject()
  const navigate = useNavigate()

  const handleRowClick = (item) => {
    navigate(`${process.env.PUBLIC_URL}/pages/concern/${category}/${item.name}/${item.projectId}/${item.assetTypId}/`)
  }

  const filteredRows = groupedAssets[category].filter((asset) =>
    asset.name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <Card className="p-10" style={{boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px', display: filteredRows.length === 0 && 'none'}}>
      <CardHeader className="pb-1 pt-3">
        <H6 className="m-0">{t(category)}</H6>
      </CardHeader>
      <CardBody
        className="p-0 custom-scrollbar rounded"
        style={{ maxHeight: 300, overflow: 'auto' }}
      >
        <div className="taskadd">
          <div className="table-responsive table-hover p-0">
            <Table hover={true}>
              <tbody>
                {filteredRows.map((item, index) => {
                  return (
                    filteredRows.length === 0 ? <tr key={index} onClick={() => handleRowClick(item)} style={{ cursor: 'pointer' }} >
                    
                    <td style={{ background: 'none' }}>
                      <P>No result.</P>
                    </td>
                  </tr> :
                    <tr key={index} onClick={() => handleRowClick(item)} style={{ cursor: 'pointer' }} >
                      <td style={{ width: '30%', background: 'none' }} className='pt-3 pb-0'>
                        <p attrH6={{ className: 'task_title_0' }}>
                          {item.name}
                        </p>
                      </td>
                      <td style={{ background: 'none' }} className='pt-3 pb-3'>
                        <P className="text-base">{item.beschreibung}</P>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default DashboardCard
