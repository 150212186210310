import React, { Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Masonry from 'react-masonry-css'
import { Card, CardHeader, Col, Container, Row } from 'reactstrap'
import { H3 } from '../../../AbstractElements'
import { useAuth } from '../../../_helper/Auth/AuthProvider'
import { useProject } from '../../../_helper/Projects/ProjectProvider'
import DashboardCard from '../../Card/DashboardCard'
import { HomeSkeleton } from '../../Skeleton/Skeleton'

const HomePage = () => {
  const {
    getAllProjects,
    groupedAssets,
    getAssetTypes,
    setProjectAssetsList,
  } = useProject()
  const { authToken } = useAuth()
  const { t } = useTranslation()
  const breakpointColumnsObj = {
    default: 2,
    1199: 2,
    700: 2,
    500: 1,
  }

  useEffect(() => {
    if (authToken) {
      //getAllProjects(authToken)
      getAssetTypes(authToken)
      setProjectAssetsList([])
    }
  }, [authToken])


  return (
    <Fragment>
      {/* <Breadcrumbs mainTitle="Home" parent="Pages" title="Home" /> */}
      <Container fluid={true}>
        <Row>
          <Col sm="12" md="10" className="offset-md-1">
            <Row style={{ marginTop: 30 }}>
              <Col sm="12">
                <Card className="text-center">
                  <CardHeader className="p-3 border-0 rounded-pill">
                    <H3> {t('Welcome to Report Portal')} </H3>
                  </CardHeader>
                </Card>
              </Col>
            </Row>

            {Object.keys(groupedAssets).length === 0 && <HomeSkeleton />}

            <Row style={{ marginBottom: 50 }}>
              <Col>
                {/* <Masonry
                  breakpointCols={breakpointColumnsObj}
                  className="my-masonry-grid masonry-with-dec my-gallery gallery-with-description row grid"
                  columnClassName="col-xl-12 col-sm-12 col-md-12 grid-item"
                >
                  {Object.keys(groupedAssets).map((item, index) => (
                    <DashboardCard category={item} key={index} />
                  ))}
                </Masonry> */}
                {Object.keys(groupedAssets).map((item, index) => (
                    <DashboardCard category={item} key={index} />
                  ))}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default HomePage
