import React, { useEffect } from 'react';
import { Search } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Input } from 'reactstrap';
import { useProject } from '../../../_helper/Projects/ProjectProvider';

const Searchbar = (props) => {
  const { t } = useTranslation();
  const {searchText, setsearchText} = useProject()
  const location = useLocation();

  const handleInputChange = (e) => {
    const value = e.target.value;
    setsearchText(value);
  };

  useEffect(() => {
    // Clear the search text when the location changes
    setsearchText('');
  }, [location, setsearchText]);

  return (
    <li className="pt-0 pb-0">
      <form>
        <div className="faq-form p-0" style={{marginTop:props?.isMobileView?0:-10,marginRight:10,marginLeft:props?.isMobileView?-70:0, width: props?.isMobileView?150:250}}>
          <Input
            className="form-control"
            type="text"
            placeholder={`${t("Search")}...`}
            value={searchText}
            onChange={handleInputChange}
            style={{ borderRadius: 25, height: 35 }}
          />
          <Search className="search-icon" />
        </div>
      </form>
    </li>
  )
}

export default Searchbar
 