import React, { useEffect, useRef, useState } from "react";
import { LogIn, User } from "react-feather";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Image, P } from "../../../AbstractElements";
import { useAuth } from "../../../_helper/Auth/AuthProvider";

const UserHeaderProfile = () => {
  const { user, logout } = useAuth();
  const [langdropdown, setLangdropdown] = useState(false);
  const history = useNavigate();
  const [name, setName] = useState();
  const { t } = useTranslation();
  const authenticated = JSON.parse(localStorage.getItem("authenticated"));
  const dropdownRef = useRef(null);

  useEffect(() => {
    setName(localStorage.getItem("Name") ? localStorage.getItem("Name") : name);
  }, []);

  const Logout = () => {
    logout();
    localStorage.setItem("login", false);
    localStorage.setItem("authenticated", false);
    history(`${process.env.PUBLIC_URL}/login`);
  };

  const toggleMenu = () => {
    setLangdropdown(!langdropdown);
  };

  // Hide dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setLangdropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <li className="language-nav" ref={dropdownRef}>
      <div className={`translate_wrapper ${langdropdown ? "active" : ""}`}>
        <div className="media profile-media" onClick={toggleMenu}>
          <Image
            attrImage={{
              className: `b-r-10 m-0 img-fluid`,
              src: `${
                authenticated
                  ? require("../../../assets/images/user/user-plain.png")
                  : require("../../../assets/images/user/user-plain.png")
              }`,
              alt: "",
              style: { width: "40px", height: "40px" },
            }}
          />
          <div className="media-body" onClick={toggleMenu}>
            <span>{user?.vorname}</span>
            <P attrPara={{ className: "mb-0 font-roboto" }}>
              {t("Settings")}
              <i className="middle fa fa-angle-down"></i>
            </P>
          </div>
        </div>
        {/* Dropdown Menu */}
        {langdropdown && (
          <div className={`more_lang active`}>
            <div className="lang">
              <User />
              <span className="lang-txt">{t("Account")}</span>
            </div>
            <div className="lang" onClick={() => Logout()}>
              <LogIn />
              <span className="lang-txt">{t("Logout")}</span>
            </div>
          </div>
        )}
      </div>
    </li>
  );
};

export default UserHeaderProfile;
