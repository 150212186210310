import React, { Fragment, useEffect, useState } from 'react';
import { Accordion, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Popover, PopoverBody, Row } from 'reactstrap';
import { H3, H6 } from '../../../AbstractElements';
import { useAuth } from '../../../_helper/Auth/AuthProvider';
import { useProject } from '../../../_helper/Projects/ProjectProvider';
import { useReport } from '../../../_helper/Reports/ReportProvider';
import CustomPagination from '../../CustomPagination';
import CommonModal from '../../Modals/common/modal';
import { TicketSkeleton } from '../../Skeleton/Skeleton';
import format from "date-fns/format";
import { parseISO, compareAsc, compareDesc, isValid } from 'date-fns';

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Example breakpoint for mobile
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isMobile;
};

const TicketPage = () => {
  const navigate = useNavigate()
  const { isDark, authToken } = useAuth()
  const { searchText, projectAssetsList, getAllProjectAssets } = useProject()
  const { isFetchingReports, reportLists, getReports, myReportLists, getMyReports, isFetchingDate, date, getDate, isFetchingAsset, assetType, getAssetType } = useReport()
  const [modalData, setModalData] = useState(null);
  const [Large, setLarge] = useState(false);
  const LargeModaltoggle = () => setLarge(!Large);
  const browserLocale = navigator.language || navigator.userLanguage; // Get the browser's locale
  const [sortBy, setSortBy] = useState('asc');
  const [popoverIndex, setPopoverIndex] = useState(null);
  const isMobile = useIsMobile();

  //for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Calculate start and end indexes of items for the current page
  const startIndex = (currentPage - 1) * itemPerPage;
  const endIndex = Math.min(startIndex + itemPerPage, reportLists.length);


  const handleClick = (item) => {
    setModalData(item)
  }

  const handleMouseEnter = (index) => {
    setPopoverIndex(index);
  };

  const handleMouseLeave = () => {
    setPopoverIndex(null);
  };

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '(...)';
    } else {
      return text;
    }
  }

  useEffect(() => {
    if (authToken) {
      getReports(authToken);
      getMyReports(authToken);
    }
  }, [authToken])

  //Array manipulation by adding percent, checklist, and dateCreated
  function modifyArray1WithPercentAndChecklist(array1, array2) {
    const percentMap = array2.reduce((acc, obj) => {
      acc[obj.parentId] = obj.percent !== undefined ? obj.percent : 0;
      return acc;
    }, {});

    const checklistMap = array2.reduce((acc, obj) => {
      acc[obj.parentId] = obj.checkList !== undefined ? obj.checkList : [];
      return acc;
    }, {});

    const browserLocale = navigator.language || navigator.userLanguage; // Get the browser's locale
    const locale = browserLocale === 'de-DE' ? 'de-DE' : undefined; // Set locale to 'de-DE' if it matches, otherwise undefined

    const dateMap = date.reduce((acc, obj) => {

      if (locale !== 'de-DE') {
        acc[obj.parentId] = new Date(obj.dateTime).toLocaleDateString(locale);
        return acc;
      }
      acc[obj.parentId] = format(new Date(obj.dateTime), 'yyyy.MM.dd');
      return acc;
    }, {});

    const typeMap = assetType.reduce((acc, arr) => {
      arr.forEach(obj => {
        acc[obj._id] = obj.name;
      });
      return acc;
    }, {});
 
    const dateCreatedMap = date.reduce((acc, obj) => {
      acc[obj.parentId] = obj.dateTime;
      return acc;
    }, {});

    array1.forEach(obj => {
      obj.percent = percentMap[obj._id] !== undefined ? percentMap[obj._id] : 0;
      obj.checkList = checklistMap[obj._id] !== undefined ? checklistMap[obj._id] : [];
      obj.dateCreated = dateMap[obj._id];
      obj.assetTypeName = typeMap[obj.typeId] || "";
      obj.dateCreatedOn = dateCreatedMap[obj._id];
    });

    return array1;
  }
  const combinedArray = modifyArray1WithPercentAndChecklist(reportLists, myReportLists);
  // Function to toggle sorting order
  const toggleSort = () => {
    setSortBy((prevSort) => (prevSort === 'desc' ? 'asc' : 'desc'));
  };

  const combinedDataProjectAssets = reportLists.map(report => {
    // Find the matching item in data based on typeId
    const matchedItem = assetType.flat().find(item => item._id === report.typeId);

    // If a match is found, combine the matched item with the report
    if (matchedItem) {
        return {
            ...report,
            projectAssets: matchedItem,
        };
    }

    // If no match is found, return the report as is
    return report;
});

  // Filter the combinedArray based on the searchText
  const filteredArray = combinedDataProjectAssets.filter(item =>
    item.state === 0 && // Filter by state first
    item.typeId !== '5f91685379f04e354891f866' && // Exclude specific typeIds
    item.typeId !== '63f30c8c971f7d687d16b0b9' && // Exclude specific typeIds
    (item.name.toLowerCase().includes(searchText.toLowerCase()) ||
     item.description.toLowerCase().includes(searchText.toLowerCase()) ||
     item.assetTypeName.toLowerCase().includes(searchText.toLowerCase()))
  );


// Filter out invalid dates
const validData = filteredArray.filter(
  (item) => item.dateCreatedOn && isValid(parseISO(item.dateCreatedOn))
);

// Sort using date-fns dynamically based on sortBy
const sortedCombinedArray = validData.sort((a, b) => {
  if (sortBy === 'desc') {
    return compareAsc(parseISO(a.dateCreatedOn), parseISO(b.dateCreatedOn));
  } else {
    return compareDesc(parseISO(a.dateCreatedOn), parseISO(b.dateCreatedOn));
  }
});

  // Function to sort the combined array based on assetTypeName
  // const sortedCombinedArray = filteredArray
  //   .slice()
  //   .sort((a, b) => {
  //     const dateA = a.dateCreatedOn || '';
  //     const dateB = b.dateCreatedOn || '';

  //     if (sortBy === 'desc') {
  //       return dateA.localeCompare(dateB);
  //     } else {
  //       return dateB.localeCompare(dateA);
  //     }
  //   });

  useEffect(() => {
    const fetchDate = async () => {
      try {
        await Promise.all(reportLists.map(async (item) => {
          getDate(authToken, item._id)
        }));

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    const fetchAsset = async () => {
      try {
        await Promise.all(reportLists.map(async (item) => {
          getAssetType(authToken, item.typeId)
        }));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchAsset();
    fetchDate();
  }, [reportLists]);

  useEffect(() => {
    // Set page to 1 when searchText is not empty
    if (searchText !== '') {
      setCurrentPage(1);
    }
  }, [searchText]);

// const sortedcombinedDataWithProjectAssets = combinedDataProjectAssets
//   .slice()
//   .sort((a, b) => {
//     const dateA = a.dateCreated || ''; // Default to empty string if undefined
//     const dateB = b.dateCreated || ''; // Default to empty string if undefined

//     if (sortBy === 'desc') {
//       return dateA.localeCompare(dateB); // Ascending order
//     } else {
//       return dateB.localeCompare(dateA); // Descending order
//     }
//   });

// const sortedcombinedDataWithProjectAssets = combinedData
// .slice()
// .sort((a, b) => {
//   const dateA = a.dateCreated || '';
//   const dateB = b.dateCreated || '';

//   if (sortBy === 'asc') {
//     return dateA.localeCompare(dateB);
//   } else {
//     return dateB.localeCompare(dateA);
//   }
// });

console.log('sortedCombinedArray', sortedCombinedArray)

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col sm="12" md="10" className="offset-md-1">
            <Row style={{ marginTop: 30 }}>
              <Col sm="12" xl="12">
                <Accordion defaultActiveKey="0">
                  <Card>
                    {
                      isFetchingReports ? (
                        <TicketSkeleton />
                      ) : (
                        (searchText !== "" && sortedCombinedArray.length === 0) || reportLists.length === 0 ? (
                          <CardHeader className="pt-3 pb-1 text-center">
                            <H6 className="m-0">No ticket found.</H6>
                          </CardHeader>
                        ) : (
                          <CardBody>
                            <div className="table-responsive table-hover p-0">
                              <Table hover={true}>
                              {sortedCombinedArray.length > 0 && (
                                <thead>
                                  <tr>
                                    <td colSpan="3" style={{ background: 'none' }}></td>
                                    <td className="text-center" style={{ background: 'none' }}>
                                      <button
                                        className="btn p-0 pt-1"
                                        style={{ width: '35px', height: '35px', borderRadius: '50%', border: '1px solid #e0e4ed' }}
                                        onClick={() => toggleSort()}
                                      >
                                        <i
                                          style={{ fontSize: 20 }}
                                          className={`for-light fa ${sortBy === 'asc' ? 'fa-sort-numeric-desc' : 'fa-sort-numeric-asc'}`}
                                        ></i>
                                        <i
                                          style={{ fontSize: 20, color: '#a8a9ad' }}
                                          className={`for-dark fa ${sortBy === 'asc' ? 'fa-sort-numeric-desc' : 'fa-sort-numeric-asc'}`}
                                        ></i>
                                      </button>
                                    </td>
                                    <td style={{ background: 'none' }}></td>
                                  </tr>
                                </thead>
                              )}
                              <tbody>
                                {sortedCombinedArray?.slice(startIndex, endIndex).map((item, index) => (
                                  <React.Fragment key={item._id}>
                                    <tr key={item._id} onClick={() => [handleClick(item), LargeModaltoggle()]}>
                                      <td style={{ width: '30%', background: 'none' }}>
                                        <H6>{isFetchingAsset ? 'Loading...' : item.assetTypeName === '' ? '...' : item.assetTypeName}</H6>
                                      </td>
                                      <td style={{ width: '30%', background: 'none' }}>
                                        {
                                        item?.projectAssets?.properties[0]?.mode === 2 ? item?.properties[0]?.valueInt 
                                        : item?.projectAssets?.properties[0]?.mode === 3 ? item?.properties[0]?.valueDateTime?.slice(0, 10) 
                                        : item?.projectAssets?.properties[0]?.mode === 4 && item?.projectAssets?.properties[0]?.customizeType === "TEXT" ? item?.properties[0]?.valueString
                                        : item?.projectAssets?.properties[0]?.mode === 4 && item?.projectAssets?.properties[0]?.customizeType === "TEXT2" ? item?.properties[0]?.valueString
                                        : item?.properties[0]?.valueString
                                        }
                                      </td>
                                      {/* <td
                                        className="text-center"
                                        style={{ width: '40%', background: 'none' }}
                                        id={`Popovers-${index}`}
                                        onMouseEnter={() => handleMouseEnter(index)}
                                        onMouseLeave={handleMouseLeave}
                                      >
                                        {item.description === '' ? '...' : truncateText(item.description, 40)}
                                      </td>
                                      {item.description !== '' && item.description.length >= 40 && (
                                        <Popover placement="top" isOpen={popoverIndex === index} target={`Popovers-${index}`} toggle={() => {}}>
                                          <PopoverBody> {item.description} </PopoverBody>
                                        </Popover>
                                      )} */}
                                      <td className="text-center" style={{ width: '20%', background: 'none' }}>
                                        {isFetchingDate ? 'Loading...' : item.dateCreated}
                                      </td>
                                      <td className="text-center" style={{ width: '20%', background: 'none' }}>
                                        <span
                                          style={{
                                            color: '#fff',
                                            backgroundColor:
                                              item.percent === 0
                                                ? '#7468fc'
                                                : item.percent >= 1 && item.percent <= 99
                                                ? '#ee2266'
                                                : '#60bb4f',
                                            padding: isMobile?'':'5px 20px',
                                            borderRadius: '4px',
                                            marginRight: '8px',
                                          }}
                                        >
                                          {item.percent} %
                                        </span>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))}
                              </tbody>
                              </Table>
                            </div>
                            {sortedCombinedArray.length > 0 && (
                              <CustomPagination
                                totalItems={sortedCombinedArray.length}
                                itemsPerPage={itemPerPage}
                                currentPage={currentPage}
                                onPageChange={handlePageChange}
                                onItemsPerPageChange={setItemPerPage}
                                startIndex={startIndex}
                                endIndex={endIndex}
                              />
                            )}
                          </CardBody>
                        )
                      )
                    }
                  </Card>

                </Accordion>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <CommonModal isOpen={Large} toggler={LargeModaltoggle} size="xl" data={modalData}>...</CommonModal>

    </Fragment>
  )
}

export default TicketPage



// import React, { Fragment, useEffect, useState } from 'react';
// import { Accordion, Table } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
// import { Card, CardBody, CardHeader, Col, Container, Popover, PopoverBody, Row } from 'reactstrap';
// import { H3, H6 } from '../../../AbstractElements';
// import { useAuth } from '../../../_helper/Auth/AuthProvider';
// import { useProject } from '../../../_helper/Projects/ProjectProvider';
// import { useReport } from '../../../_helper/Reports/ReportProvider';
// import CustomPagination from '../../CustomPagination';
// import CommonModal from '../../Modals/common/modal';
// import { TicketSkeleton } from '../../Skeleton/Skeleton';
// import format from "date-fns/format";

// const TicketPage = () => {
//   const navigate = useNavigate()
//   const { isDark, authToken } = useAuth()
//   const { searchText, projectAssetsList, getAllProjectAssets } = useProject()
//   const { isFetchingReports, reportLists, getReports, myReportLists, getMyReports, isFetchingDate, date, getDate, isFetchingAsset, assetType, getAssetType } = useReport()
//   const [modalData, setModalData] = useState(null);
//   const [Large, setLarge] = useState(false);
//   const LargeModaltoggle = () => setLarge(!Large);
//   const [sortBy, setSortBy] = useState('asc');
//   const [popoverIndex, setPopoverIndex] = useState(null);

//   //for pagination
//   const [currentPage, setCurrentPage] = useState(1);
//   const [itemPerPage, setItemPerPage] = useState(10);

//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//   };

//   // Calculate start and end indexes of items for the current page
//   const startIndex = (currentPage - 1) * itemPerPage;
//   const endIndex = Math.min(startIndex + itemPerPage, reportLists.length);


//   const handleClick = (item) => {
//     setModalData(item)
//   }

//   const handleMouseEnter = (index) => {
//     setPopoverIndex(index);
//   };

//   const handleMouseLeave = () => {
//     setPopoverIndex(null);
//   };

//   function truncateText(text, maxLength) {
//     if (text.length > maxLength) {
//       return text.substring(0, maxLength) + '(...)';
//     } else {
//       return text;
//     }
//   }

//   useEffect(() => {
//     if (authToken) {
//       getReports(authToken);
//       getMyReports(authToken);
//     }
//   }, [authToken])

//   //Array manipulation by adding percent, checklist, and dateCreated
//   function modifyArray1WithPercentAndChecklist(array1, array2) {
//     const percentMap = array2.reduce((acc, obj) => {
//       acc[obj.parentId] = obj.percent !== undefined ? obj.percent : 0;
//       return acc;
//     }, {});

//     const checklistMap = array2.reduce((acc, obj) => {
//       acc[obj.parentId] = obj.checkList !== undefined ? obj.checkList : [];
//       return acc;
//     }, {});

//     const browserLocale = navigator.language || navigator.userLanguage; // Get the browser's locale
//     const locale = browserLocale === 'de-DE' ? 'de-DE' : undefined; // Set locale to 'de-DE' if it matches, otherwise undefined

//     const dateMap = date.reduce((acc, obj) => {

//       if (locale !== 'de-DE') {
//         acc[obj.parentId] = new Date(obj.dateTime).toLocaleDateString(locale);
//         return acc;
//       }
//       acc[obj.parentId] = format(new Date(obj.dateTime), 'yyyy.MM.dd');
//       return acc;
//     }, {});

//     const typeMap = assetType.reduce((acc, arr) => {
//       arr.forEach(obj => {
//         acc[obj._id] = obj.name;
//       });
//       return acc;
//     }, {});
 
//     array1.forEach(obj => {
//       obj.percent = percentMap[obj._id] !== undefined ? percentMap[obj._id] : 0;
//       obj.checkList = checklistMap[obj._id] !== undefined ? checklistMap[obj._id] : [];
//       obj.dateCreated = dateMap[obj._id];
//       obj.assetTypeName = typeMap[obj.typeId] || "";
//     });

//     return array1;
//   }
//   const combinedArray = modifyArray1WithPercentAndChecklist(reportLists, myReportLists);
//   // Function to toggle sorting order
//   const toggleSort = () => {
//     setSortBy(sortBy === 'asc' ? 'desc' : 'asc');
//   };

//   // Filter the combinedArray based on the searchText
//   const filteredArray = combinedArray.filter(item =>
//     item.name.toLowerCase().includes(searchText.toLowerCase()) ||
//     item.description.toLowerCase().includes(searchText.toLowerCase())
//   );

//   // Function to sort the combined array based on assetTypeName
//   const sortedCombinedArray = filteredArray
//     .slice()
//     .sort((a, b) => {
//       const dateA = a.dateCreated || '';
//       const dateB = b.dateCreated || '';

//       if (sortBy === 'asc') {
//         return dateA.localeCompare(dateB);
//       } else {
//         return dateB.localeCompare(dateA);
//       }
//     });

//   useEffect(() => {
//     const fetchDate = async () => {
//       try {
//         await Promise.all(reportLists.map(async (item) => {
//           getDate(authToken, item._id)
//         }));

//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };
//     const fetchAsset = async () => {
//       try {
//         await Promise.all(reportLists.map(async (item) => {
//           getAssetType(authToken, item.typeId)
//         }));
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };
//     fetchAsset();
//     fetchDate();
//   }, [reportLists]);

//   useEffect(() => {
//     // Set page to 1 when searchText is not empty
//     if (searchText !== '') {
//       setCurrentPage(1);
//     }
//   }, [searchText]);


//   const combinedData = reportLists.map(report => {
//     // Find the matching item in data based on typeId
//     const matchedItem = assetType.flat().find(item => item._id === report.typeId);

//     // If a match is found, combine the matched item with the report
//     if (matchedItem) {
//         return {
//             ...report,
//             projectAssets: matchedItem,
//         };
//     }

//     // If no match is found, return the report as is
//     return report;
// });

// const sortedcombinedDataWithProjectAssets = combinedData
//   .slice()
//   .sort((a, b) => {
//     const dateA = a.dateCreated || ''; // Default to empty string if undefined
//     const dateB = b.dateCreated || ''; // Default to empty string if undefined

//     if (sortBy === 'desc') {
//       return dateA.localeCompare(dateB); // Ascending order
//     } else {
//       return dateB.localeCompare(dateA); // Descending order
//     }
//   });

// // const sortedcombinedDataWithProjectAssets = combinedData
// // .slice()
// // .sort((a, b) => {
// //   const dateA = a.dateCreated || '';
// //   const dateB = b.dateCreated || '';

// //   if (sortBy === 'asc') {
// //     return dateA.localeCompare(dateB);
// //   } else {
// //     return dateB.localeCompare(dateA);
// //   }
// // });

//   return (
//     <Fragment>
//       <Container fluid={true}>
//         <Row>
//           <Col sm="12" md="10" className="offset-md-1">
//             <Row style={{ marginTop: 30 }}>
//               <Col sm="12" xl="12">
//                 <Accordion defaultActiveKey="0">
//                   <Card>
//                     {
//                       isFetchingReports ? (
//                         <TicketSkeleton />
//                       ) : (
//                         (searchText !== "" && sortedCombinedArray.length === 0) || reportLists.length === 0 ? (
//                           <CardHeader className="pt-3 pb-1 text-center">
//                             <H6 className="m-0">No ticket found.</H6>
//                           </CardHeader>
//                         ) : (
//                           <CardBody>
//                             <Table hover={false}>
//                               {reportLists.length > 0 && (
//                                 <thead>
//                                   <tr>
//                                     <td colSpan="3" style={{ background: 'none' }}></td>
//                                     <td className="text-center" style={{ background: 'none' }}>
//                                       <button
//                                         className={`btn p-0 pt-1`}
//                                         style={{ width: '35px', height: '35px', borderRadius: '50%', border: '1px solid #e0e4ed' }}
//                                         onClick={() => toggleSort()}
//                                       >
//                                         <i
//                                           style={{ fontSize: 20 }}
//                                           className={`for-light fa ${sortBy === 'asc' ? 'fa-sort-numeric-asc' : 'fa-sort-numeric-desc'}`}
//                                         ></i>
//                                         <i
//                                           style={{ fontSize: 20, color: '#a8a9ad' }}
//                                           className={`for-dark fa ${sortBy === 'asc' ? 'fa-sort-numeric-asc' : 'fa-sort-numeric-desc'}`}
//                                         ></i>
//                                       </button>
//                                     </td>
//                                     <td style={{ background: 'none' }}></td>
//                                   </tr>
//                                 </thead>
//                               )}
//                               <tbody>
//                                 {sortedcombinedDataWithProjectAssets.slice(startIndex, endIndex).map((item, index) => (
//                                   <React.Fragment key={item._id}>
//                                     <tr key={item._id} onClick={() => [handleClick(item), LargeModaltoggle()]}>
//                                       <td style={{ width: '30%', background: 'none' }}>
//                                         <H6>{isFetchingAsset ? 'Loading...' : item.assetTypeName === '' ? '...' : item.assetTypeName}</H6>
//                                       </td>
//                                       <td style={{ width: '30%', background: 'none' }}>
//                                         {
//                                         item?.projectAssets?.properties[0]?.mode === 2 ? item?.properties[0]?.valueInt 
//                                         : item?.projectAssets?.properties[0]?.mode === 3 ? item?.properties[0]?.valueDateTime?.slice(0, 10) 
//                                         : item?.projectAssets?.properties[0]?.mode === 4 && item?.projectAssets?.properties[0]?.customizeType === "TEXT" ? item?.properties[0]?.valueString
//                                         : item?.projectAssets?.properties[0]?.mode === 4 && item?.projectAssets?.properties[0]?.customizeType === "TEXT2" ? item?.properties[0]?.valueString
//                                         : ''
//                                         }
//                                       </td>
//                                       {/* <td
//                                         className="text-center"
//                                         style={{ width: '40%', background: 'none' }}
//                                         id={`Popovers-${index}`}
//                                         onMouseEnter={() => handleMouseEnter(index)}
//                                         onMouseLeave={handleMouseLeave}
//                                       >
//                                         {item.description === '' ? '...' : truncateText(item.description, 40)}
//                                       </td>
//                                       {item.description !== '' && item.description.length >= 40 && (
//                                         <Popover placement="top" isOpen={popoverIndex === index} target={`Popovers-${index}`} toggle={() => {}}>
//                                           <PopoverBody> {item.description} </PopoverBody>
//                                         </Popover>
//                                       )} */}
//                                       <td className="text-center" style={{ width: '20%', background: 'none' }}>
//                                         {isFetchingDate ? 'Loading...' : item.dateCreated}
//                                       </td>
//                                       <td className="text-center" style={{ width: '20%', background: 'none' }}>
//                                         <span
//                                           style={{
//                                             color: '#fff',
//                                             backgroundColor:
//                                               item.percent === 0
//                                                 ? '#7468fc'
//                                                 : item.percent >= 1 && item.percent <= 99
//                                                 ? '#ee2266'
//                                                 : '#60bb4f',
//                                             padding: '5px 20px',
//                                             borderRadius: '4px',
//                                             marginRight: '8px',
//                                           }}
//                                         >
//                                           {item.percent} %
//                                         </span>
//                                       </td>
//                                     </tr>
//                                   </React.Fragment>
//                                 ))}
//                               </tbody>
//                             </Table>
//                             {reportLists.length > 0 && (
//                               <CustomPagination
//                                 totalItems={reportLists.length}
//                                 itemsPerPage={itemPerPage}
//                                 currentPage={currentPage}
//                                 onPageChange={handlePageChange}
//                                 onItemsPerPageChange={setItemPerPage}
//                                 startIndex={startIndex}
//                                 endIndex={endIndex}
//                               />
//                             )}
//                           </CardBody>
//                         )
//                       )
//                     }
//                   </Card>

//                 </Accordion>
//               </Col>
//             </Row>
//           </Col>
//         </Row>
//       </Container>

//       <CommonModal isOpen={Large} toggler={LargeModaltoggle} size="xl" data={modalData}>...</CommonModal>

//     </Fragment>
//   )
// }

// export default TicketPage