import axios from 'axios'
import Cookies from 'js-cookie'
import React, { createContext, useContext, useEffect, useState } from 'react'

// Create a context for authentication
const AuthContext = createContext()

// Create a provider component
export const AuthProvider = ({ children }) => {
  const [isDark, setIsDark] = useState(false);
  const [user, setUser] = useState(null)
  const [authToken, setAuthToken] = useState(null)

  const backendUrl = process.env.REACT_APP_BACKEND_URL
  const backendPort = process.env.REACT_APP_BACKEND_PORT

  let apiUrl = `${backendUrl}`

  if (backendPort) {
    apiUrl += `:${backendPort}`
  }

  useEffect(() => {
    const storedToken = Cookies.get('token')
    if (storedToken) {
      setAuthToken(storedToken)
      getUserInfo(storedToken)
    } else {
      localStorage.setItem('login', JSON.stringify(false))
      if (window.location.pathname !== '/api/test/about' && window.location.pathname !== '/login') {
        window.location.href = '/login'; // Redirect to login page route
      }
    }
  }, [])

  const getUserInfo = async (token) => {
    try {
      const response = await axios.get((apiUrl += '/api/user-info'), {
        headers: {
          token: token,
        },
      })
      setUser(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const login = (token) => {
    Cookies.set('token', token)
    setAuthToken(token)
  }

  const logout = () => {
    setAuthToken(null)
    Cookies.remove('token')
  }

  return (
    <AuthContext.Provider
      value={{ isDark, setIsDark, getUserInfo, user, authToken, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  )
}

// Custom hook to access the authentication context
export const useAuth = () => useContext(AuthContext)
