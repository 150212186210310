import React, { useState, useEffect } from 'react';

const About = () => {
    const [data, setData] = useState(null);

    const backendUrl = process.env.REACT_APP_BACKEND_URL
    const backendPort = process.env.REACT_APP_BACKEND_PORT

    let apiUrl = `${backendUrl}`

    if (backendPort) {
        apiUrl += `:${backendPort}`
    }

    useEffect(() => {
        fetch(apiUrl += '/api/test/about')
            .then(response => response.json())
            .then(data => setData(data));
    }, []);

    return (
        <div style={{ backgroundColor: "#202124", color: "white" }}>
            {data ? (
                <pre>{JSON.stringify(data, null, 2)}</pre>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
    
};

export default About;
