import React, { Fragment, useContext, useEffect, useLayoutEffect, useState, useRef } from "react";
import { default as CustomContext, default as CustomizerContext } from "../../_helper/Customizer";
import { MENUITEMS } from "./Menu";
import SidebarIcon from "./SidebarIcon";
import SidebarLogo from "./SidebarLogo";
import SidebarMenu from "./SidebarMenu";

const Sidebar = (props) => {
  const { toggleSidebar, toggleIcon } = useContext(CustomizerContext);
  const customizer = useContext(CustomContext);
  const sidebarRef = useRef(null);
  //const { toggleIcon } = useContext(CustomContext);
  const id = window.location.pathname.split("/").pop();
  const defaultLayout = Object.keys(customizer.layout);

  const [toggle, setToggle] = useState(false);

  const layout = id ? id : defaultLayout;
  // eslint-disable-next-line
  const [mainmenu, setMainMenu] = useState(MENUITEMS);

  const [width, setWidth] = useState(0);

  const activeClass = () => {
    // document.querySelector('.sidebar-link').classList.add('active');
    document.querySelector(".bg-overlay1").classList.add("active");
  };

  const setNavActive = (item) => {
    MENUITEMS.map((menuItems) => {
      menuItems.Items.filter((Items) => {
        if (Items !== item) {
          Items.active = false;
          document.querySelector(".bg-overlay1").classList.remove("active");
        }
        if (Items.children && Items.children.includes(item)) {
          Items.active = true;
          document.querySelector(".sidebar-links").classList.add("active");
        }
        if (Items.children) {
          Items.children.filter((submenuItems) => {
            if (submenuItems.children && submenuItems.children.includes(item)) {
              Items.active = true;
              submenuItems.active = true;
              return true;
            } else {
              return false;
            }
          });
        }
        return Items;
      });
      return menuItems;
    });
    item.active = !item.active;
    setMainMenu({ mainmenu: MENUITEMS });
  };

  const openCloseSidebar = () => {
    toggleSidebar(!toggleIcon);
  };


  const screenWidth = useWindowSize();
  function useWindowSize() {
    const [width, setWidth] = useState(window.innerWidth);
  
    useLayoutEffect(() => {
      function updateSize() {
        setWidth(window.innerWidth);
  
        if (window.innerWidth <= 1007) {
          toggleSidebar(true);
          document.querySelector('.page-wrapper').className = 'page-wrapper compact-wrapper';
        } else {
          toggleSidebar(false);
        }
      }
  
      window.addEventListener('resize', updateSize);
      updateSize();
  
      return () => window.removeEventListener('resize', updateSize);
    }, []);
  
    return width;
  }

  /**this is use hide the side when screen size is greater than 990 */
  useEffect(() => {
    // Reload the page if the screen width changes from <= 990 to > 990
    if (screenWidth > 990) {
      document.querySelector('.page-wrapper').className = 'page-wrapper horizontal-wrapper';
    }
  }, [screenWidth]);

  /**this is use to close the sidebar when click outside the sidebar */
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the sidebar (sidebarRef)
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        toggleSidebar(!toggleIcon);
      }
    };
    // Add event listener on mount
    document.addEventListener("mousedown", handleClickOutside);
    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Fragment>
      <div ref={sidebarRef} className={`sidebar-wrapper ${toggleIcon ? "close_icon" : ""}`} sidebar-layout="stroke-svg" >
        <SidebarIcon />
        <SidebarLogo openCloseSidebar={openCloseSidebar} />
        <SidebarMenu setMainMenu={setMainMenu} props={props} setNavActive={setNavActive} activeClass={activeClass} width={width} openCloseSidebar={openCloseSidebar} setToggle={setToggle} toggle={toggle} />
      </div>

    </Fragment>
  );
};

export default Sidebar;
