import React, { useContext, useState } from 'react';
import { Grid, ChevronLeft } from 'react-feather';
import { Link } from 'react-router-dom';
import { Image } from '../../AbstractElements';
import InformLogo from '../../assets/images/logo/inpro-logo.png';
import CustomizerContext from '../../_helper/Customizer';

const SidebarLogo = ({openCloseSidebar}) => {
  const { mixLayout, toggleSidebar,toggleIcon, layout, layoutURL } = useContext(CustomizerContext);
  const [toggle, setToggle] = useState(false);

  // const openCloseSidebars = () => {
  //   console.log('toggleIcon', toggleIcon)
  //   toggleSidebar(!toggleIcon);
  // };

  const layout1 = localStorage.getItem('sidebar_layout') || layout;

  return (
    <div className='logo-wrapper'>
      {layout1 !== 'compact-wrapper dark-sidebar' && layout1 !== 'compact-wrapper color-sidebar' && mixLayout ? (
        <Link to={`${process.env.PUBLIC_URL}/pages/home`} style={{fontSize: 16, fontWeight: 450, color:'#09256a'}}>
          <Image attrImage={{ className: 'img-fluid d-inline', src: `${InformLogo}`, alt: '', style: { width: '40px', height: '40px', marginRight: 15 } }} />
          InForm
        </Link>
      ) : (
        <Link to={`${process.env.PUBLIC_URL}/pages/home`} style={{fontSize: 16, fontWeight: 450, color:'#09256a'}}>
          <Image attrImage={{ className: 'img-fluid d-inline', src: `${InformLogo}`, alt: '', style: { width: '40px', height: '40px', marginRight: 15, filter: 'brightness(0) invert(100%)' } }} />
          <span style={{filter: 'brightness(0) invert(100%)'}}>InForm</span>
        </Link>
      )}
      <div className='back-btn' onClick={openCloseSidebar}>
        {/* <i className='fa fa-angle-left'></i> */}
        <ChevronLeft className='status_toggle middle sidebar-toggle' />
      </div>
      {/* <div className='toggle-sidebar' onClick={openCloseSidebar}>
        <Grid className='status_toggle middle sidebar-toggle' />
      </div> */}
    </div>
  );
};

export default SidebarLogo;
