import moment from 'moment';
import { format } from 'date-fns'
import React, { Fragment, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Form, Row } from 'reactstrap';
import { Btn, H4 } from '../../../AbstractElements';
import { useProject } from '../../../_helper/Projects/ProjectProvider';
import { useAuth } from "../../../_helper/Auth/AuthProvider";
import Masonry from 'react-masonry-css'
import './style.scss'

const ConcernPage = () => {
  const { user } = useAuth();
  const { t } = useTranslation()
  const history = useNavigate();
  const isConcernPage = useLocation().pathname.includes("/pages/concern");
  const { projectId : projectId , assetId: assetId, category: category, assetName: assetName } = useParams();
  const { projectAssetsList, getAllProjectAssets, submitTicket, isTicketSubmitted, setIsTicketSubmitted, groupedAssets } = useProject()
  const [currentDateTime, setCurrentDateTime] = useState('')

  const breakpointColumnsObj = {
    default: 2,
    1199: 2,
    700: 2,
    500: 1,
  }

  useEffect(() => {
    const getCurrentDateTime = () => {
      const currentDate = new Date()
      const formattedDateTime = format(
        currentDate,
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
      )
      setCurrentDateTime(formattedDateTime)
    }
    getCurrentDateTime()
    const interval = setInterval(getCurrentDateTime, 1000) // Update current date and time every second
    return () => clearInterval(interval)
  }, [])

  let assetFormList;
  if (projectAssetsList.length === 0) {
    getAllProjectAssets(assetId)
  } else {
      const projectAssetFormList = projectAssetsList?.filter((i)=>i._id === assetId).map((item)=>{ return (item)})
    assetFormList = projectAssetFormList[0];
  }

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [hasChanged, setHasChanged] = useState(false);
  // Watch all fields to track current values
  const watchedFields = watch();
  const [loading, setLoading] = useState(false);

  const submitTicketForm = async (data) => {
    const today = new Date(); // Get the current date
    const formattedDate = moment(today).format('YYYY-MM-DDTHH:mm:ss[Z]');

    // Construct an array of form field objects
    const propertiesData = assetFormList?.properties.map((form, index) => {
      if (form.mode === 1) {
        return {
          propertyTypeEntryId: form._id,
          typeId: assetId,
          valueDateTime: formattedDate,
          valueBool: ['true', 'yes', '1'].includes(data[`Name${index}`]?.toLowerCase())
        };
      } else if (form.mode === 2) {
        return {
          propertyTypeEntryId: form._id,
          typeId: assetId,
          valueDateTime: formattedDate,
          valueInt: data[`Name${index}`]
        };
      } else if (form.mode === 3) {
        return {
          propertyTypeEntryId: form._id,
          typeId: assetId,
          valueDateTime: formattedDate,
        };
      } else if (form.mode === 4) {
        return {
          propertyTypeEntryId: form._id,
          typeId: assetId,
          valueDateTime: formattedDate,
          valueString: data[`Name${index}`]
        };
      } else if (form.mode === 5) {
        return {
          propertyTypeEntryId: form._id,
          typeId: assetId,
          valueDateTime: formattedDate,
          valueDecimal: data[`Name${index}`]
        };
      }
      else if (form.mode === 7) {
        return {
          propertyTypeEntryId: form._id,
          typeId: assetId,
          valueDateTime: formattedDate,
          valueString: data[`Name${index}`]
        };
      }
      else {
        return {
          propertyTypeEntryId: form._id,
          typeId: assetId,
          valueDateTime: formattedDate,
          valueTimeStamp: data[`Name${index}`]
        };
      }
    });

    const combineData = {
      data: {
        chatRoomThema: [
          {
            "title": "allgemein",
            "description": "",
            "createdOnDate": currentDateTime,
            "changedOnDate": currentDateTime
          }
        ],
        parentId: projectId,
        project_id: projectId,
        typeId: assetId,
        name: data.subject,
        // description: data.description, 
        description: '',
        properties: propertiesData,
      }

    };
    //console.log('combineData', combineData)
    await submitTicket(combineData, setLoading);
  };

  if (isTicketSubmitted) {
    setIsTicketSubmitted(false)
    history(`${process.env.PUBLIC_URL}/pages/home`)
  }

  const customizeEntries = assetFormList?.customizeEntries || [];
  const assetProperty = assetFormList?.properties.map((properties) => {
    const options = { option: customizeEntries.filter(customizeEntry => properties.customizeType === customizeEntry._id) };
    return { ...properties, options };
  }) || [];


  // Set initial values when the component mounts
  const initialValues = assetProperty?.reduce((acc, form, index) => {
    acc[`Name${index}`] = ''; // Initialize empty fields
    return acc;
  }, {});

  useEffect(() => {
    // Check if any field value has changed
    const changed = Object.entries(watchedFields).some(
      ([key, value]) => initialValues[key] !== value
    );
    setHasChanged(changed);
  }, [watchedFields]);

  return (
    <Fragment>
      {/* <ToastContainer autoClose={1000}/> */}
      {/* <Breadcrumbs mainTitle="Concern" parent="Pages" title="Concern" /> */}
      <Container fluid={true}>
        <Row>
          <Col sm="12" md="10" className="offset-md-1">
            <Row style={{ marginTop: 30 }}>
              <Col sm="12">
                {
                  projectAssetsList.length === 0 ?
                    <Card>
                      <CardHeader><Col sm="12"><Skeleton count={1} height={30} width={'30%'} style={{ borderRadius: 10, marginBottom: 10 }} /></Col></CardHeader>
                      <CardBody>
                        <Row>
                          <Col sm="6"><Skeleton count={3} height={58} style={{ borderRadius: 10, marginBottom: 10 }} /></Col>
                          <Col sm="6"><Skeleton count={3} height={58} style={{ borderRadius: 10, marginBottom: 10 }} /></Col>
                          <Col sm="12"><Skeleton count={1} height={80} style={{ borderRadius: 10, marginBottom: 10 }} /></Col>
                        </Row>
                      </CardBody>
                      <CardFooter><Skeleton count={1} height={35} width={'8%'} style={{ borderRadius: 10 }} /></CardFooter>
                    </Card>
                    : <Card>
                      <CardHeader>
                        <H4>{category} - {assetName}</H4>
                        {/* <div>{`${format(new Date(), 'yy-MM-dd')}_${user?.username}`}</div> */}
                      </CardHeader>

                      <CardBody>
                        <Form className='needs-validation' noValidate='' onSubmit={handleSubmit(submitTicketForm)}>
                          <Row style={{ marginBottom: 50 }}>
                            <Col md='12' style={{ display: 'none' }}>
                              <div className="form-floating mb-3">
                                <input className="form-control" id='validationCustom01' readOnly type='text' name={`subject`} {...register('subject', { required: true })} value={`${format(new Date(), 'yy-MM-dd')}_${user?.username}`} />
                                <label htmlFor="floatingInput22"><span style={{ color: '#F07B05' }}>*</span>Subject</label>
                                <span className='d-block' style={{ color: '#F07B05' }}>{errors[`subject`] && 'This field is required'}</span>
                              </div>
                            </Col>
                            <Col>
                              <Masonry
                                breakpointCols={breakpointColumnsObj}
                                className="my-masonry-grid masonry-with-dec my-gallery gallery-with-description row grid"
                                columnClassName="col-xl-12 col-sm-12 col-md-12 grid-item"
                              >
                                {
                                  assetProperty.map((form, index) => (
                                    // <Col sm="6" key={index}>
                                    <div className="form-floating mb-3 position-relative">
                                      {
                                        form.mode === 2 ? <input className="form-control" id='validationCustom01' type='number' name={`Name${index}`} {...register(`Name${index}`, { required: form.mandatory })} />
                                          : form.mode === 3 ? <input className="form-control" id='validationCustom01' type='date' name={`Name${index}`} {...register(`Name${index}`, { required: form.mandatory })} />
                                            : form.mode === 4 && form.customizeType === "TEXT" ? <input className="form-control" id='validationCustom01' type='text' name={`Name${index}`} {...register(`Name${index}`, { required: form.mandatory })} />
                                              : form.mode === 4 && form.customizeType === "TEXT2" ?
                                                <textarea className="form-control textarea-custom-scrollbar" style={{ height: "auto", minHeight: 80, maxHeight: "300px" }} name={`Name${index}`} {...register(`Name${index}`, { required: form.mandatory })}
                                                  onInput={(e) => {
                                                    e.target.style.height = "auto";
                                                    e.target.style.height = (e.target.scrollHeight) + "px";
                                                  }}></textarea>
                                                : form.mode === 7 ?
                                                  <select
                                                    className="form-select form-control form-select"
                                                    id={`Name${index}`}
                                                    name={`Name${index}`}
                                                    {...register(`Name${index}`, { required: form.mandatory })}
                                                    defaultValue=""
                                                  >
                                                    <option value=''></option>
                                                    {form.options.option[0].predefiniedProperties.map((property, i) => (
                                                      <option key={i} value={property.value}>
                                                        {property.value}
                                                      </option>
                                                    ))}
                                                  </select>
                                                  :
                                                  <div className="radio-container">
                                                    <label className="custom-radio-label w-100" htmlFor={`Name${index}`}>
                                                      {form.mandatory === true && <span style={{ color: '#F07B05' }}>*</span>}
                                                      {form.name}
                                                    </label>

                                                    <div className="radio-options" style={{ display: 'flex', alignItems: 'center' }}>
                                                      <div className="radio-option" style={{ display: 'flex', alignItems: 'center' }}>
                                                        <input
                                                          className="custom-radio"
                                                          id={`no-${index}`}
                                                          type="radio"
                                                          name={`Name${index}`}
                                                          value={false}
                                                          {...register(`Name${index}`, { required: true })}
                                                        />
                                                        <label className="custom-radio-label" htmlFor={`no-${index}`} style={{ marginLeft: '8px' }}>
                                                          {t('No')}
                                                        </label>
                                                      </div>

                                                      <div className="radio-option" style={{ display: 'flex', alignItems: 'center', marginRight: '15px' }}>
                                                        <input
                                                          className="custom-radio"
                                                          id={`yes-${index}`}
                                                          type="radio"
                                                          name={`Name${index}`}
                                                          value={true}
                                                          {...register(`Name${index}`, { required: true })}
                                                        />
                                                        <label className="custom-radio-label" htmlFor={`yes-${index}`} style={{ marginLeft: '8px' }}>
                                                          {t('Yes')}
                                                        </label>
                                                      </div>
                                                    </div>
                                                  </div>


                                        // <div class="checkbox-container">
                                        //   <input className="custom-checkbox" id='validationCustom01' type='checkbox' name={`Name${index}`} {...register(`Name${index}`, { required: form.mandatory })} /> 
                                        //   <label className="custom-check-label w-100" htmlFor={`Name${index}`}>{form.mandatory === true&&<span style={{color:'#F07B05'}}>*</span>}{form.name}</label>
                                        // </div>
                                      }

                                      {
                                        form.mode !== 1 &&
                                        <label htmlFor={`Name${index}`} className="w-100">{form.mandatory === true && <span style={{ color: '#F07B05' }}>*</span>}{form.name}</label>
                                      }
                                      {
                                        form.mode === 1 ?
                                          <span className='d-block' style={{ color: '#F07B05' }}>{errors[`Name${index}`] && 'Please choose one option and cannot submit before'}</span>
                                          : <span className='d-block' style={{ color: '#F07B05' }}>{errors[`Name${index}`] && 'This field is required'}</span>
                                      }
                                    </div>
                                    // </Col>
                                  ))
                                }
                              </Masonry>

                            </Col>
                          </Row>
                          <Btn
                            attrBtn={{ color: 'primary', disabled: loading }}
                            className="text-end"
                          >
                            {loading ? 'Submitting...' : 'Submit'}
                          </Btn>
                        </Form>
                      </CardBody>
                    </Card>
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default ConcernPage