export const MENUITEMS = [
  {
    menutitle: 'General',
    menucontent: 'Dashboards,Widgets',
    Items: [
      {
        title: 'Home',
        icon: 'home',
        path: `${process.env.PUBLIC_URL}/pages/home`,
        type: 'link',
      },
      {
        title: 'Tickets',
        icon: 'support-tickets',
        path: `${process.env.PUBLIC_URL}/pages/tickets`,
        type: 'link',
      },
      {
        title: 'Concern',
        icon: 'form',
        // path: `${process.env.PUBLIC_URL}/pages/concern`,
        type: 'link',
      },
    ],
  },
];